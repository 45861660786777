/* barlow-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  src: url('barlow-v12-latin-300.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('barlow-v12-latin-300.woff')
      format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url('barlow-v12-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('barlow-v12-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: url('barlow-v12-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('barlow-v12-latin-500.woff')
      format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* barlow-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: url('barlow-v12-latin-600.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('barlow-v12-latin-600.woff')
      format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* material-icons-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('material-icons-v140-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('material-icons-v140-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* work-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('work-sans-v18-latin-300.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('work-sans-v18-latin-300.woff')
      format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* work-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('work-sans-v18-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('work-sans-v18-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* work-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('work-sans-v18-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('work-sans-v18-latin-500.woff')
      format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* work-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('work-sans-v18-latin-600.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('work-sans-v18-latin-600.woff')
      format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* work-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('work-sans-v18-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('work-sans-v18-latin-700.woff')
      format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* work-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  src: url('work-sans-v18-latin-800.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('work-sans-v18-latin-800.woff')
      format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-tc-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 300;
  src: url('noto-sans-tc-v26-latin-300.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('noto-sans-tc-v26-latin-300.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-tc-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  src: url('noto-sans-tc-v26-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('noto-sans-tc-v26-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-tc-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  src: url('noto-sans-tc-v26-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('noto-sans-tc-v26-latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
